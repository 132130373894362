header{
    height: 100vh;
    padding-top: 5rem;
    overflow: hidden;
    /* background-color: blue; */
}
.header__container {
     text-align: center;
     height: 100%;
     /* background-color: red; */
     position: relative;
}
header h2 {
    font-size: 1rem;
}
/* ========= CTA =========*/
.cta {
    margin-top: 1.2rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ========= Header Socials =========*/
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
    font-size: 1.2rem;
}
.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: hsl(var(--primary));
}

/* ========= ME : Image =========*/
.me {
    background: linear-gradient(hsl(var(--primary)), transparent);
    width: 22rem;
    height: 50vh;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 0 10rem 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
    transition: var(--transition);
}
.me:hover {
    cursor: default;
    border-radius: 0 12rem 0 12rem ;
    /* transform: scale(1.1); */
}
.me img:hover{
    transform: translatey(-20px);
    /* transform: skewy(30deg); */
    transition: var(--transition);
}

/* ========= Scroll Down =========*/
.scroll__down{
    position: absolute;
    right: -4.3rem;
    bottom: 8rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
    height: 30px;
    width: 200px;
    /* background-color: red; */
}
.scroll__down::before {
    content: '';
    width: 3rem;
    height: 1px;
    background: hsl(var(--primary));
    position: absolute;
    right: 0;
    margin-top: 0.8rem;
}


/* ============== Spotlight Cursor for Ismail Ahmadi=============== */


/* ============== Media Query (Medium Devices)=============== */
@media screen and (max-width:1024px){
    header{
        height: 70vh;
    }
    .me{
        height: 40vh;
    }

}
/* ============== Media Query (Small Devices)=============== */
@media screen and (max-width:600px){
    header{
        height: 100vh;
        padding-top: 4rem;
    }
    .header__socials, .scroll__down{
        display: none;
    }
    .me{
        padding: 3rem 1.5rem 1.5rem 1.5rem;
    }

}