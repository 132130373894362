.contact__container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap:12%;

}
.contact__options {
    display: flex;
    flex-direction: column;
    gap:1.2rem
}
.contact__option {
    background: hsl(var(--primary2));
    padding: 1.2rem;
    border-radius: 1.2rem 0;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}
.contact__option:hover {
    background: transparent;
    border-color: hsl(var(--primary));
}
.contact__option-icon {
    color: hsl(var(--primary));
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}
.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}
.contact__option h5 {
    color: hsl(var(--txt));
}
/* ================== FORM ================*/
form {
    display: flex;
    flex-direction: column;
    gap:1.2rem
}
.input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem 0;
    background:transparent;
    border: 1px solid hsl(var(--primary));
    resize: none;
    color: hsl(var(--txt));
    font-family: var(--font-family);

}
.messageSent {
    display: none;
}
.messageSent__content {
    display: grid;
    grid-template-columns: 1fr 0.8rem;
    width: 40%;
    height: auto;
    margin: 3rem 45% 1rem;
    border-radius: 2rem 0;
    background-color: hsl(var(--primary2));
}
.messageSent__content-text {
    height: min-content;
    padding: 1.5rem 0;
}
.messageSent__content-icon {
    font-size: 2rem;
    margin: 1rem 0;
}
.messageSent__content-close button {
    color: hsl(var(--txt));
}
.messageSent__block {
    display: block;
}
.messageSent__none {
    display: none;
}
.messageSent button {
    background: transparent;
}
/* ============== Media Query (Medium Devices)=============== */
@media screen and (max-width:1024px){
    .container.contact__container{
        grid-template-columns: 1fr;
        gap:2rem;
        width: 58%;
    }
    .messageSent__content {
        width: 80%;
        margin: 2rem auto;
    }
 }
 /* ============== Media Query (Small Devices)=============== */
 @media screen and (max-width:600px){
    .container.contact__container{
        width: var(--container-width-sm);
    }
 }

/* "service_gfz6eg9","template_nevhefa" */