footer {
    background: hsl(var(--primary));
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
}
footer a, small {
    color: hsl(var(--muted));
}
.footer__logo {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: inline-block;
}
.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap:2rem;
    margin: 0 auto 3rem;
}
.footer__socials {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;
}
.footer__socials a {
    background: hsl(var(--primary2));
    color: var(--color-white);
    padding: 0.8rem;
    border-radius: 0.7rem 0;
    display: flex;
    border: 1px solid transparent;
    transition: var(--transition);

}
.footer__socials a:hover {
    background: transparent;
    color: hsl(var(--primary2));
    border-color: hsl(var(--primary2));
    transform: scale(1.08);
}
.footer__copyright {
    margin-bottom: 4rem;
    color: vhsl(var(--primary2));
}
/* ============== Media Query (Small Devices)=============== */
@media screen and (max-width:600px){
    .permalinks {
        flex-direction: column;
        gap:1.5rem;
    }
    .footer__socials{
        margin-bottom: 1rem;
    }

}