.container.testimonials__container{
    width: 40%;
    padding-bottom: 3rem;
}
.client__avatar{
    width: 5rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50% ;
    margin: 0 auto 1rem;
    border: 0.4rem solid hsl(var(--primary));
}
.testimonial {
    background: hsl(var(--primary2));
    text-align: center;
    padding: 2rem;
    border-radius: 2rem 0;
    user-select: none;
}
.client__review {
    color: hsl(var(--txt));
    display: block;
    width: 80%;
    margin: 0.8rem auto 0;
}
/* ============== Media Query (Medium Devices)=============== */
@media screen and (max-width:1024px){
    .container.testimonials__container  {
        width: 60%;
    }
 }
 /* ============== Media Query (Small Devices)=============== */
 @media screen and (max-width:600px){
    .container.testimonials__container, .client__review  {
        width: var(--container-width-sm);
    }
 }
 .swiper-pagination-clickable .swiper-pagination-bullet {
    background: hsl(var(--primary));

 }
 .swiper-button-next::after{
    /* color: red; */
    /* background-color: red; */
    width: 10px;
    height: 10px;
    display: none;
    /* font-size: 120px; */
 }
 .swiper-button-prev::after {
    display: none;
 }
.swiper-scrollbar .swiper-scrollbar-horizontal{
    color: red;
}
.swiper-scrollbar-drag {
    display: none;
}
.swiper-scrollbar {
    display: none;
     /* color: red; */
}