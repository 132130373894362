.experience__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}
.experience__container > div {
    background: hsl(var(--primary2));
    padding: 2.4rem 5rem;
    border-radius: 1.5rem 0;
    border: 1px solid transparent;
    transition: var(--transition);
}
.experience__container > div:hover {
    background: transparent;
    cursor: default;
    border-color: hsl(var(--primary));
}
.experience__container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: hsl(var(--primary));
}
.experience__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
}
.experience__details {
    display: grid;
    grid-template-columns: 2rem 1fr;
    /* background-color: red; */
    gap: 1rem;
}
.experience__details-icon {
    margin-top: 0.5rem;
    color: hsl(var(--primary));
    width: 2.5rem;
    font-size: 1rem;
    /* background-color: black; */
}
@media screen and (max-width:1024px){
    .experience__container {
        grid-template-columns: 1fr;
    }
    .experience__content{
        padding: 1rem;
    }
 }
 /* ============== Media Query (Small Devices)=============== */
 @media screen and (max-width:600px){
    .experience__container {
        gap:1rem;
    }
    .experience__container > div{
        width: 100%;
        padding:2rem 1rem;
    }
    .experience__container > div h4 {
        font-size: 0.8rem;
    }
    .experience__container > div small {
        font-size: 0.7rem;
    }
 }
