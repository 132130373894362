.services__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
}
.service {
    background-color: hsl(var(--primary2));
    border-radius: 2rem 0 2rem 0;
    border: 1px solid hsl(var(--primary));
    height: fit-content;
    transition: var(--transition);
}
.service:hover {
    cursor: default;
    background: transparent;
    border-color: hsl(var(--primary2));
}
.service__head{
    background: hsl(var(--primary));
    padding: 2rem;
    border-radius: 1.5rem 0 2rem 0rem;
    box-shadow: 0 2rem1rem rgba(0, 0, 0, 0.1);
}
.service__head h3 {
    /* color: var(--color-bg); */
    font-size:1 1rem;
    text-align: center;
    /* background-color: red; */
}
.service__list {
    padding: 2rem;
}
.service__list li{
    display: grid;
    grid-template-columns: 1.2rem 1fr;
    gap:0.2rem;
    margin-bottom: 0.8rem;
}
.service__list-icon {
    color: hsl(var(--primary));
    margin-top: 2px;
    font-size: 0.7rem;
}
/* ============== Media Query (Medium Devices)=============== */
@media screen and (max-width:1024px){
    .services__container  {
        grid-template-columns: 1fr 1fr;
        gap:2rem;
    }
    .service {
        height: auto;
    }
 }
 /* ============== Media Query (Small Devices)=============== */
 @media screen and (max-width:600px){
    .services__container  {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
 }
