/* ================== NAV for Small Devices ============= */
.small-devices {
    background: rgba(0, 0, 0, 0, 0.3);
    width: max-content;
    display: none;
    pad: 0.9rem 2.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    /* display: flex; */
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
    border: 0.05rem solid hsl(var(--txt));
}
.small-devices > div {
    display: flex;
}
.small-devices a {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: hsl(var(--txt));
    font-size: 1.1rem;
} 
.small-devices a:hover{
    background: rgba(0, 0, 0, 0.6) ;
}
.small-devices a.active {
    background: hsl(var(--primary));
}
/* ================== NAV for large Devices ============= */
.lg-devices {
    background: hsl(var(--primary));
    width: var(--container-width-lg);
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 6rem;
    z-index: 2;
    height: 3rem;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    border-radius: 0.8rem 0;
    /* display: flex; */
    /* gap: 0.8rem; */
    /* border-radius: 3rem; */
    /* backdrop-filter: blur(15px); */
    /* border: 0.1rem solid rgba(0, 0, 0, 0.2); */
}
.lg-devices-bg {
    background: hsl(var(--primary));
    opacity: 0.8;
}
.lg-devices ul {
    display: flex;
    /* outline: 1px solid red; */
    justify-content: right;
}
.lg-devices ul li {
    background: transparent;
    margin: 0 1rem;
    display: inline-block;
    
    /* justify-content: center; */

}
.lg-devices a {
    /* background: var(--color-light); */
    padding: 0.5rem;
    /* border-radius: 50%; */
    display: flex;
    color: hsl(var(--muted));
    font-size: 1rem;
} 
/* .lg-devices a:hover{
    /* background: rgba(0, 0, 0, 0.6) ; */
    /* border-bottom: 1px solid red; */
.lg-devices a.active-lg{
    /* background: hsl(var(--primary)); */
    color: hsl(var(--txt));
    /* color: red; */
}
/* ============== Media Query (Medium Devices)=============== */
@media screen and (max-width:1324px){
    .lg-devices{
        font-size: 0.8rem;
        padding-top: 0.3rem ;
    }
    .lg-devices ul li {
        margin: 0 0.5rem;
    }


    .lg-devices a {
        font-size: 0.8rem;
    }
}
@media screen and (max-width:1024px){
    .lg-devices {
        display: none;
    }
    .small-devices {
        display: block;
    }
}
/* ============== Media Query (Small Devices)=============== */
@media screen and (max-width:600px){

}